

















































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      language: "language"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    })
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_65_less);
    },
    onVerify() {
      window.open(this.$paths.verify + "am-65-less/", "_blank");
    }
  }
});
